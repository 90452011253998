
.css-tpe3od-control{
  margin-left: 0px !important;
}

.app-header {
  display: flex;
  flex-direction: row; /* Stack logo and search bar */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  padding: 20px 50px; /* Reduced padding for smaller screens */
  background-color: #ffffff;
}
.btn-row{
  display: flex;  
  flex-direction: row;
  gap: 10px;
  margin-left: 15px;
}
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.slogan {
  font-size: .75rem;
  font-weight: 500;
  text-align: center;
  font-style: italic;
  color: #1a1a1a;
}
.dark-mode .slogan {
  color: #fff;
}
.dark-mode .modal-container{
  background-color: #1a1a1a;
}
.dark-mode .modal-container{
  color: #fff;
}
.app-logo {
  width: 200px; /* Smaller logo on small devices */
  height: auto;
  margin-bottom: 10px; /* Space between logo and search bar */
}

.search-bar {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Center the search bar */
  width: 60%; /* Full width */
}
.credits{
  text-decoration: none;
  font-weight: bold;
}
@media (max-width: 1448px) {
  .app-header {
    display: flex;
    flex-direction: column;
    
  }
  .logo-container {
    width: 100%;
    justify-content: center;
    gap: 5px;
  }
  .search-bar {
    justify-content: center;
    width: 100%;
    scale: .85;
  }
}

@media (max-width: 1008px) {
  .search-bar {
    scale: .75;
  }
}
@media (max-width: 810px) {
  .search-bar {
    scale: .65;
  }
}

@media (max-width: 1446px) {
  .search-bar{
    position: relative;
    z-index: 100;
  }
}

/* Responsive adjustments for small devices */
  @media (max-width: 476px) {
  .app-header {
    margin: 0 auto;
    flex-direction: column;
    padding: 25px 5px; /* Smaller padding for very small screens */
  }
  .btn-row{
    margin-top: 15px;
    gap: 25px;
    margin-left: 0px;
  }
  .app-logo {
    width: 200px;
    margin-right: 0px;
    /* Further reduce logo size on very small screens */
  }
  .css-w6mmta-control{
    width: 180px !important;
  }
  h1 {
    font-size: 1rem; /* Smaller font for header if needed */
  }
  select{
    width: 100%;

  }
  .search-bar {
    scale: 1;
    justify-content: center ;
    margin: 0 auto;
    flex-direction: column; /* Stack search bar items vertically */
    width: 100%; /* Full width on small screens */
  }
}
@media (max-width: 768px){  
  .app-header{
    display: flex;
    flex-direction: column;
  }
  .search-bar{
    scale: 1;
    justify-content: center !important;
    flex-direction: column;
  }
  .app-logo{
    margin-right: 0px;
  }
  .logo-container{
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 1568px){  

  .app-logo{
    margin-right: 200px;
  }
}
@media (max-width: 968px){  

  .app-logo{
    margin-right: 0px;
  }
  
}

.app-footer {
  text-align: center; /* Center the text */
  padding: 20px; /* Padding for the footer */
  background-color: #e0f7fa; /* Background color */
  border-top: 1px solid #ddd; /* Top border for separation */
  position: relative;
  bottom: 0;
}

.app-footer p {
  padding: 0px 50px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin: 5px 0; /* Space between paragraphs */
  font-size: 0.9rem; /* Font size */
  color: #333; /* Text color */
}

.dark-mode .app-header {
  background-color: #1e1e1e; /* Dark header background */
}

.dark-mode .day-header {
  background-color: #292929 ;
  color: #ffffff; 
}

/* Dark mode button styles */
.dark-mode .print-button,
.dark-mode .theme-toggle-button {
  color: #90caf9; /* Light color for icons/buttons in dark mode */
}
.custom-icon{
  line-height: 1;
}
.week-display {
  padding: 10px;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.dark-mode .week-display{
  color: #181818;
}
.search-bar select{
  margin-left: -1px;
}
.dark-mode .search-bar select {
  width: 100%;
  background-color: #444; /* Dark background for select */
  color: #ffffff; /* Light text color */
  border: 1px solid #666; /* Lighter border */
}
/* Existing light mode styles... */

/* Dark Mode Styles */
.dark-mode .app-footer {
  background-color: #1e1e1e; /* Dark background for footer */
  color: #ffffff ; /* Light text color */
  border-top: 1px solid #444; /* Darker border for separation */
}
.dark-mode .app-footer p {
  color: #ffffff ;
 /* Light text color */
}

.dark-mode .app-footer a {
  color: #90caf9; /* Light color for links in dark mode */
}

.dark-mode .app-footer a:hover {
  color: #ffffff; /* Change color on hover for better visibility */
}
/* Light mode scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Culoare pentru scrollbar */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Spațiere pentru thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Culoare pentru track */
}

/* Dark mode scrollbar */
.dark-mode::-webkit-scrollbar {
  width: 12px;
}

.dark-mode::-webkit-scrollbar-thumb {
  background-color: #0288d1; /* Culoare pentru scrollbar */
  border-radius: 10px;
  border: 2px solid #333; /* Spațiere pentru thumb */
}

.dark-mode::-webkit-scrollbar-track {
  background-color: #333; /* Culoare pentru track */
}
/* Custom scrollbar for the react-select menu list (Light Mode) */
.react-select__menu-list {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #c1c1c1 #f1f1f1; /* Thumb color | Track color */
}

.react-select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.react-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}

.react-select__menu-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Custom scrollbar for the react-select menu list (Dark Mode) */
.dark-mode .react-select__menu-list {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #555 #333; /* Thumb color | Track color */
}

.dark-mode .react-select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.dark-mode .react-select__menu-list::-webkit-scrollbar-track {
  background: #333;
}

.dark-mode .react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

.dark-mode .react-select__menu-list::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.dark-mode .buy-me-beer-button,
.dark-mode .paypal-donate-button {
  color: #fff !important;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
/* Container to hold snowflakes */
/* Container to hold snowflakes */
/* Container to hold snowflakes */
.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

/* Individual snowflakes */
.snowflake {
  position: absolute;
  top: -10px;
  color: white;
  pointer-events: none;
  animation: fall linear infinite;
  transform: rotate(var(--tilt, 0deg));
}

@keyframes fall {
  to {
    transform: translateY(100vh) rotate(var(--tilt, 0deg));
  }
}

@keyframes melt {
  to {
    opacity: 0;
    transform: scale(0);
  }
}

/* Snowflake melting animation */
@keyframes melt {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5); /* Shrink size */
    opacity: 0; /* Fade out */
  }
}



/* Donate buttons container */
.donate-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
  flex-wrap: wrap;
}

/* Common styles for both buttons */
.buy-me-beer-button,
.paypal-donate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px; /* Înălțime redusă */
  padding: 0 15px;
  margin: 5px;
  border-radius: 4px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  box-sizing: border-box;
  transition: transform 0.2s ease;
  white-space: nowrap;
}

.buy-me-beer-button {
  background-color: #FFDD00;
  color: #000000;
}

.paypal-donate-button {
  background-color: #0070ba;
  color: #fff;
}

/* Button icon styling */
.paypal-donate-button img {
  width: 16px;
  margin-right: 6px;
}

/* Hover effect */
.buy-me-beer-button:hover,
.paypal-donate-button:hover {
  transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .donate-buttons {
    gap: 0px;
  }
  .buy-me-beer-button,
  .paypal-donate-button {
    padding: 0px 8px !important;
    height: 30px;
    font-size: 12px;
    min-width: 150px;
  }

  .paypal-donate-button img {
    width: 14px;
    margin-right: 4px;
  }
}

@media (max-width: 480px) {
  .buy-me-beer-button,
  .paypal-donate-button {
    height: 30px;
    font-size: 11px;
  }

  .paypal-donate-button img {
    width: 12px;
    margin-right: 3px;
  }
}
.clear-schedules-button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #ff4d4f;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-schedules-button:hover {
  background-color: #d9363e;
}

.clear-schedules-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

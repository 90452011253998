@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
 
}
.dark-mode body{
  background-color: #1e1e1e;
}

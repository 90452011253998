.dark-mode .container-login{
    background-color: #000000 !important;
}

.dark-mode .input-login{
    background-color: #2a2a2a;
}
.dark-mode h1{
    color: #fff;
}

.dark-mode .label-login{
    color: #fff;
}

.dark-mode input{
    color: #fff;
}
.dark-mode .page-login {
background-color: #1e1e1e;
}
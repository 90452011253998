/* styles.css */
/* Pentru întreaga pagină (fundal și culoarea textului) */
body {
    transition: background-color 0.5s ease, color 0.5s ease; /* Durata și efectul de easing */
  }
  
  .header {
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  /* Alte elemente care își schimbă stilurile */
  .card {
    transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease;
  }
  
  button {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
/* General layout */
.dashboard {
    display: flex;
    min-height: 100vh;
    background-color: #fff;
  }
  .dark-mode .timetable-container {
    background-color: #1e1e1e;
}
  .app-logo{
    margin: 0 auto;
  }
  .sidebar {
    width: 350px;
    background-color: #292929;
    color: white;
    padding: 50px;
    transition: transform 0.3s ease; /* Smooth open/close animation */
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
  }
  
  .sidebar li {
    margin-top: 10px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    font-size: 1.1rem;
    gap: 8px;
    padding: 12px 26px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    background-color: #ffffff15;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      padding: 50px;
      transform: translateX(-100%); /* Sidebar is hidden initially by moving it off-screen */
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1000;
    }
  
    .sidebar.open {
      transform: translateX(0); /* When opened, it slides into view */
    }
  }
  
  .burger-menu {
    display: none;
    background: none;
    border: none;
    font-size: 32px;
    color: #000;
    cursor: pointer;
  }
  .dark-mode .burger-menu{
    color: #fff;
  }
  @media (max-width: 768px) {
    .burger-menu {
      display: block;
    }
    .burger-menu .material-icons{
        align-items: center;
        display: flex;
      }
    .header h2{
    display: none;      
    }
  }
  
.header-controls{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
  .logout-btn{
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .logout-btn .material-icons{
    font-size: 23px !important;
  }
  .sidebar a {
    color: white;
    text-decoration: none;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #fff;
  }
  .dark-mode .main-content{
    background-color: #1e1e1e;
  }

  .card{
    background-color: #fff;
  }
  .dark-mode .card{
    background-color: #1e1e1e;

  }
  .card h1, .card h2{
    color: #1e1e1e;
  }
  .dark-mode .card{
    background-color: #292929;
  }
  .dark-mode .card h1, .dark-mode .card h2{
    color: #fff;
  }
  .dark-mode .label-text{
    color: #fff;
  }
  .addEventmodal{
    z-index: 1000 !important;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 20px;
    color: #000;
    background-color: #fff;
  }
  .header h2{
    font-size: 1.2em;
  }
  
  .theme-btn {
    border-radius: 25px;
    background-color: #424242;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }
  
  .dark-mode .sidebar {
    background-color: #292929;
  }
  
  .dark-mode .header {
    background-color: #292929;
    color: #fff;
  }
 
  .schedule-modal{
    background-color: #fff;
  }
.schedule-title{
  color: #1e1e1e;
}
.dark-mode .schedule-modal{
  background-color: #1e1e1e;
}
.dark-mode .schedule-title{
color: #fff;
}
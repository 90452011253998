/* SearchBar.css */
.search-bar {
  display: flex;
  gap: 10px;
  z-index: 1999;
  margin: 20px 0px;
  justify-content: flex-end;
  align-items: center;
}

.week-type-display {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 20px;
}

.week-type-row {
  display: flex;
  align-items: center;
}
.css-za7a2t-control{
  min-width: 230px !important;
}
.dark-mode .css-za7a2t-control{
  min-width: 230px !important;
}
.css-xn9zbk-control{
  min-width: 230px !important;
}
.dark-mode .css-xn9zbk-control{
  min-width: 230px !important;
}

.week-line {
  width: 30px; /* Adjusted width */
  height: 4px; /* Slightly bigger height */
  border: none;
  margin-right: 10px;
}

.even-week {
  background-color: orange;
}

.odd-week {
  background-color: #7CFC00;
}

.week-text {
  color: #000;
  font-size: 0.8rem;
}
.dark-mode .week-text {
  color: #fff;
}
.bold {
  font-weight: bold;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .week-type-display {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
  .btn-row{
    margin-top: 15px;
  }
  .week-type-row {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .search-bar{
    margin-left: 0px;
    flex-wrap: wrap;
    margin-top: 20px !important;
  }
}


select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc; /* Default border color */
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff; /* Default background color */
  color: #333; /* Default text color */
}

/* Dark mode styles for select */
.dark-mode select {
  background-color: #444; /* Dark background */
  color: #ffffff; /* Light text */
  border: 1px solid #666; /* Lighter border */
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.material-icons{
  user-select: none;
  font-size: 28px !important;
}
.print-button, .theme-toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  outline: 1.9px solid #656565;
  color: #000; /* Change color as needed */
}

.print-button:hover, .theme-toggle-button:hover{
  outline: 1.9px solid #979797;
}

/* Dark mode button styles */
.dark-mode .print-button,
.dark-mode .theme-toggle-button {
  color: #90caf9; /* Light color for buttons in dark mode */
}

.share-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 15px;
  min-width: 400px;
  color: #000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}


.modal-content-home {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.modal-content.dark-mode {
  background-color: #1e1e1e;
  color: #fff;
}

.close-button.red {
  background-color: #d32f2f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
}

.close-button.red:hover {
  background-color: #b71c1c;
}
.qr-title {
  font-size: 22px !important;
  font-weight: bold;
  margin-top: 0 !important;
  margin-bottom: 20px;
  color: #000;
}

.dark-mode .qr-title {
  color: #fff;
}

.qr-code-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.subtitle {
  width: 80%;
  margin: 0 auto;
  font-size: 14px;
  color: #555;
}
.dark-mode .subtitle {
  color: #ccc;
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.divider hr {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.divider span {
  margin: 0 10px;
  color: #555;
}

.input-container.copied .share-input {
  border-color: #0288d1;
}

.copy-message {
  color: #4caf50;
  margin-top: 10px;
}

.input-container.success .share-input {
  border-color: green;
  transition: border-color 0.3s;
}

.share-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
}

/* Dark Mode Styles */
.dark-mode .modal-content-home {
  background-color: #1e1e1e;
  color: #fff;
}

.dark-mode .divider hr {
  border-top: 1px solid #444;
}

.dark-mode .divider span {
  color: #ccc;
}

.dark-mode .share-input {
  background-color: #292929;
  border-color: #444;
  color: #fff;
}

/* Mobile Animation */
@media (max-width: 768px) {
  .share-modal{
    min-width: 300px;
  }
  .modal-content-home {
    animation: slide-up 0.3s forwards;
    transform: translateY(100%);
  }

  @keyframes slide-up {
    to {
      transform: translateY(0);
    }
  }
}

/* Hover Effects */
.share-input:hover {
  border-color: #0288d1;
}
/* Mobile Animation */
@media (max-width: 768px) {
  .share-modal {
    animation: slide-down 0.3s forwards;
    position: fixed;
    top: 0; /* Adjust the top margin */
    left: 0;
    right: 0;
    transform: translateY(-50%);
    border-radius: 0 0 15px 15px;
    max-width: none;
    width: 100%;
    height: auto;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  .share-modal.slide-down {
    animation: slide-down 0.3s forwards;
  }

  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* Slide-Up Animation (For Hiding) */
  .share-modal.slide-up {
    animation: slide-up 0.3s forwards;
  }
  

  @keyframes slide-up {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(0%);
    }
  }

  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content-home {
    padding: 20px;
    border-radius: 0 0 15px 15px;
  }

  .close-button.red {
    margin-top: 10px;
  }
}

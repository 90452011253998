
.timetable-container {
  display: grid;
  min-height: 80vh;
  z-index: 999;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  padding: 15px;
}


.time-slot-counter {
  display: inline-block;
  border: 1px solid #0288d1;
  color: #000;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
  margin-right: 8px;
  font-size: 0.9rem;
  font-weight: bold;
}

.groups{
  color: #181818;
}
.dark-mode .groups{
  color: #fff;
}
.dark-mode .timetable-container {
  background-color: #181818; /* Dark background for timetable */
}

.dark-mode .timetable-cell {
  background-color: #292929; /* Dark cell background */
  border: 1px solid #444; /* Lighter border for visibility */
}


.dark-mode .timetable-event {
  background-color: #3a3a3aa2; /* Darker event background */
  border-left: 4px solid #0288d1; /* Keep the blue border for event */
}

.dark-mode .timetable-event .subject {
  color: #ffffff; /* Light subject text */
}

.dark-mode .timetable-event .room,
.dark-mode .timetable-event .professor {
  color: #cccccc; /* Lighter text for room and professor */
}

.dark-mode .day-header {
  background-color: #292929; /* Dark background for day header */
  color: #ffffff; /* Light text */
}

.dark-mode .timetable-cell-header {
  background-color: #292929; /* Dark background for cell headers */
  color: #ffffff; /* Light text */
}

.dark-mode .delete-admin {
  background-color: rgba(194, 0, 0, 0.824); /* Dark mode delete button */
  color: #fff;
}

/* Dark mode adjustments for hover effects */
.dark-mode .admin-timetable .timetable-cell:hover {
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.4); /* Light hover box shadow */
}
.dark-mode .time-slot-counter{
  color: #fff;
}

.dark-mode .admin-timetable .timetable-event:hover {
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.4); /* Light hover box shadow */
}
/* Ensure delete button looks good in dark mode */
.dark-mode .delete-button {
  background-color: #dc2626;
  color: #f9fafb;
}

/* Additional styling for mobile time slot display */
.time-slot {
  display: flex;
  align-items: center;
  margin-bottom: 8px !important;
}
.dark-mode .time-slot{
  color: #fff;
}
.admin-timetable .timetable-cell {
  transition: box-shadow 0.3s ease;
}
.dark-mode .delete-admin {
  background-color: rgba(194, 0, 0, 0.824);
}
.delete-admin {
  background-color: rgba(194, 0, 0, 0.824);
}
.admin-timetable .timetable-cell:hover {
  box-shadow: 0px 4px 8px rgba(0, 120, 255, 0.4); /* Blue box shadow */
}

/* Admin-only hover effect for timetable events */
.admin-timetable .timetable-event {
  transition: box-shadow 0.3s ease;
}

.admin-timetable .timetable-event:hover {
  box-shadow: 0px 4px 8px rgba(0, 120, 255, 0.4); /* Blue box shadow */
}

/* Timetable Cell Styling */
.timetable-cell {
  border: 1px solid #ddd;
  padding: 8px; /* Reduced padding */
  min-height: 120px; /* Reduced minimum height */
  text-align: center;
  background-color: #f9f9f9;
  position: relative;
  border-radius: 6px; /* Reduced border radius */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Timetable Header (Day names, time slots) */
.timetable-cell-header {
  font-weight: bold;
  font-size: 1em; /* Reduced font size */
  min-height: 20px;
  background-color: #e0f7fa !important;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timetable-cell-header:nth-child(1) {
  border: none !important;
}

.dark-mode .timetable-cell-header{
  background-color: #292929 !important;
}
/* Day Header Styling */
.day-header {
  font-weight: bold;
  font-size: 1.2em; /* Adjusted font size for readability */
  text-align: center;
  background-color: #e0f7fa;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px; /* Reduced border radius */
}

/* Event Styling */
.timetable-event {
  background-color: #e1f5fea6;
  border-left: 4px solid #0288d1; /* Keep the border-left for week type colors */
  padding: 8px; /* Reduced padding */
  margin: 4px 0; /* Reduced margin */
  width: 100%;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.timetable-event:hover{
  transform: scale(1.03);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.google-calendar-icon {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
@media (max-width: 768px) {
  .google-calendar-icon {
    display: block;
  }
}
.timetable-event:hover .google-calendar-icon {
  display: block;
}

.timetable-cell.two-events .timetable-event {
  height: 100%;
  display: flex;
  font-size: .98em;
  flex-direction: column;
  justify-content: center;
}
.timetable-event .subject {
  font-weight: 600;
  font-size: 1em; /* Slightly smaller for better readability */
}

.timetable-event .room,
.timetable-event .professor {
  font-size: 0.85em; /* Adjusted font size for better readability */
  color: #777;
}

.available-icon {
  color: #787878; /* Keep the color as is */
  font-size: 2.5em; /* Adjust size for better fit */
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .timetable-container {
    display: block;
    margin: 0 auto;
  }

  .day-header {
    font-weight: bold;
    font-size: 1.3em; /* Bigger font for day header */
    margin-bottom: 10px;
    text-align: center;
    background-color: #e0f7fa;
    padding: 8px;
    border-radius: 6px;
  }

  /* Adjusted to set a stable min-height for timetable cells */
  .timetable-cell {
    margin: 15px 10px;
    min-height: 100px; /* Adjusted height */
    padding: 12px; /* Adjusted padding */
  }

  .timetable-event {
    min-width: 200px;
    padding: 12px;
    margin-bottom: 10px;
  }

  .time-slot {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1em;
  }

  .day-header {
    display: block;
  }

  /* Hide the time slots from the main timetable on mobile */
  .timetable-cell-header {
    display: none; /* Hide the headers on mobile */
  }
}

/* Stacking for Very Small Devices */
@media (max-width: 480px) {
  .timetable-container {
    display: block;
  }
  .event-type{
    background-color: #0288d1;
    display: block;
    padding: 4px 8px;
    width: fit-content;
    margin: 5px auto;
    color: #fff;
    font-size: 0.7em;
    border-radius: 10px;
  }
  
  .timetable-cell {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 10px; /* Adjusted padding for small screens */
  }

  .timetable-event {
    padding: 10px;
  }

  .timetable-event .subject {
    font-size: 1em; /* Adjust font size for small screens */
  }

  .timetable-event .professor,
  .timetable-event .room {
    font-size: 0.8em; /* Smaller font for better readability */
  }
}

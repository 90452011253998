/* logs.css */

.logs-container {
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .dark-mode .logs-container {
    background-color: #1e1e1e;
    color: #ddd;
  }
  
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .filter-container label {
    font-weight: bold;
  }
  
  .filter-container select,
  .filter-container input[type="text"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .dark-mode .filter-container select,
  .dark-mode .filter-container input[type="text"] {
    background-color: #333;
    color: #ddd;
    border: 1px solid #444;
  }
  
  .logs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .logs-table th,
  .logs-table td {
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .logs-table th {
    background-color: #efefef;
    color: #333;
    font-weight: bold;
  }
  
  .dark-mode .logs-table th {
    background-color: #333;
    color: #ddd;
  }
  
  .logs-table tbody tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  
  .dark-mode .logs-table tbody tr:nth-child(even) {
    background-color: #2e2e2e;
  }
  
  .no-logs {
    text-align: center;
    font-style: italic;
    color: #999;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-controls button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-controls span {
    font-weight: bold;
  }
  
  .dark-mode .pagination-controls button {
    background-color: #0056b3;
  }
  
  .pagination-controls button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  .filter-container select{
    margin-left: 0px;
  }
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .filter-container {
      flex-direction: column;
    }
  
    .logs-table th, .logs-table td {
      font-size: 14px;
      padding: 8px;
    }
  
    .pagination-controls button {
      font-size: 12px;
    }
  }
/* Card layout for mobile screens */
@media (max-width: 600px) {
  .logs-table {
    display: none; /* Hide the table on small screens */
  }

  .log-card {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .dark-mode .log-card {
    background-color: #333;
    color: #ddd;
  }

  .log-card div {
    margin-bottom: 5px;
  }

  .log-card label {
    font-weight: bold;
  }
}

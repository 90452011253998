/* Main container */
.entity-management-container {
  max-width: 100%;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
}
.entity-actions{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
/* Entity type selector */
.entity-type-selector {
  margin-bottom: 20px;
}
.btn-edit{
  padding: 5px 10px;
} 
/* Add Entity Form */
.entity-add-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.entity-add-form input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.btn-add {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.btn-add:hover {
  background-color: #0056b3;
}

/* Entity Row */
.entity-row-container {
  margin-top: 20px;
}

.entity-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  transition: background-color 0.2s;
}

.entity-row:hover {
  background-color: #f1f1f1;
}

.entity-name {
  font-weight: bold;
}

/* Delete Button */
.btn-delete {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-delete:hover {
  background-color: #c82333;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 600px) {
  .entity-add-form {
    width: 100%;
  }

  .entity-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .entity-add-form input,
  .btn-add {
    width: 100%;
  }

  .pagination-controls {
    flex-direction: column;
    gap: 10px;
  }
}
.entity-management-container h2{
  font-size: 25px;
  margin-bottom: 20px;
}
/* Dark Mode Support */
.dark-mode .entity-management-container {
  background-color: #292929;
  color: #fff;
}

.dark-mode .entity-add-form input {
  background-color: #444;
  border: 1px solid #555;
  color: #fff;

}

.dark-mode .entity-search-bar input{
  background-color: #444;
  border: 1px solid #555;
  color: #fff;
}

.dark-mode .btn-add {
  background-color: #1a73e8;
}

.dark-mode .btn-add:hover {
  background-color: #155bb5;
}

.dark-mode .entity-row {
  background-color: #444;
  border: 1px solid #555;
}

.dark-mode .entity-row:hover {
  background-color: #555;
}

.dark-mode .btn-delete {
  background-color: #e63946;
}

.dark-mode .btn-delete:hover {
  background-color: #b73239;
}

.dark-mode .pagination-controls button {
  background-color: #1a73e8;
}
/* Search Bar */
.entity-search-bar {
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .entity-add-form,
  .entity-search-bar {
    width: 100%;
  }

  .search-input {
    width: 100%;
  }

  .pagination-controls {
    flex-direction: column;
    gap: 10px;
  }
}

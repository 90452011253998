/* General Styling */
.animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 10px;
    max-height: 75vh;
  }
  
  .animation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Lottie Player Styling */
  dotlottie-player {
    width: 500px;
    height: 500px;
    transition: width 0.3s, height 0.3s; /* Smooth transition for resizing */
  }
  
  /* Text Styling */
  .info-text h2 {
    font-size: 2rem;
    margin-top: 20px;
    color: #333;
  }
  
  .info-text p {
    font-size: 1.2rem;
    color: #666;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .animation-container{
        height: 50vh;
    }
    .info-text h2 {
      font-size: 1.5rem;
    }
  
    .info-text p {
      font-size: 1rem;
    }
  }

  @media (max-width: 431px) {
    .animation-container{
        height: 50vh;
    }
    .info-text{
        padding: 10px 20px;
    }
    .info-text p{
        margin-top: 10px;
    }
  }
  
  /* Dark Mode */
  .dark-mode .animation-container {
    background-color: #1e1e1e;
  }
  
  .dark-mode .info-text h2 {
    color: #f0f0f0;
  }
  
  .dark-mode .info-text p {
    color: #b0b0b0;
  }
  
.user-management {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-table th,
  .user-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .user-table th {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
  }
  
  .user-table td {
    border-bottom: 1px solid #ddd;
  }
  
  .user-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button,
  .add-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
  }
  
  .edit-button {
    background-color: #28a745;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .add-button {
    background-color: #007bff;
    margin-bottom: 20px;
  }
  
  .edit-button:hover {
    background-color: #218838;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  
  /* Modal Styling */
.modal {
    position: fixed;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: fit-content;
    max-width: 90%;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .modal h3 {
    text-align: center;
    color: #333;
    font-size: 22px;
    margin-bottom: 20px;
  }
  
  /* Input fields styling */
  .modal input,
  .modal select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    margin-left: 0px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #555;
    background-color: #f9f9f9;
    transition: border-color 0.2s;
  }
  
  .modal input:focus,
  .modal select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Button container styling */
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  /* Button styling */
  .modal button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
  }
  
  .save-button {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: #ffffff;
  }
  
  .save-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
  }
  
  /* Overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    .modal {
      width: 90%;
      padding: 20px;
    }
  
    .modal h3 {
      font-size: 18px;
    }
  
    .modal input,
    .modal select {
      font-size: 13px;
    }
  
    .modal button {
      font-size: 14px;
    }
  }
  
  
  
  .save-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: #fff;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }
  /* User Management */
.dark-mode .user-management {
    color: #ffffff;
    background-color: #292929;
}

.dark-mode .user-table {
    background-color: #444;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.dark-mode .user-table th {
    background-color: #0056b3;
    color: #ffffff;
}

.dark-mode .user-table td {
    border-bottom: 1px solid #555;
    color: #e0e0e0;
}

.dark-mode .user-table tr:hover {
    background-color: #555;
}

/* Action Buttons */
.dark-mode .edit-button {
    background-color: #218838;
}

.dark-mode .delete-button {
    background-color: #c82333;
}

.dark-mode .add-button {
    background-color: #0056b3;
}

.dark-mode .edit-button:hover {
    background-color: #1e7e34;
}

.dark-mode .delete-button:hover {
    background-color: #bd2130;
}

.dark-mode .add-button:hover {
    background-color: #004085;
}

/* Modal Styling */
.dark-mode .modal {
    background-color: #444;
    color: #ffffff;
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.dark-mode .modal h3 {
    color: #ffffff;
}

.dark-mode .modal input,
.dark-mode .modal select {
    background-color: #555;
    color: #ffffff;
    border: 1px solid #666;
}

.dark-mode .modal input:focus,
.dark-mode .modal select:focus {
    border-color: #007bff;
    outline: none;
}

/* Button Container */
.dark-mode .save-button {
    background-color: #0056b3;
    color: #ffffff;
}

.dark-mode .cancel-button {
    background-color: #444;
    color: #ffffff;
}

.dark-mode .save-button:hover {
    background-color: #004085;
}

.dark-mode .cancel-button:hover {
    background-color: #333;
}

/* Overlay Background */
.dark-mode .overlay {
    background: rgba(0, 0, 0, 0.8);
}

/* Table Hover Effect */
.dark-mode .user-table tr:hover {
    background-color: #555;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .dark-mode .modal {
        width: 90%;
        padding: 20px;
        background-color: #333;
    }

    .dark-mode .modal h3 {
        font-size: 18px;
    }

    .dark-mode .modal input,
    .dark-mode .modal select {
        font-size: 13px;
    }

    .dark-mode .modal button {
        font-size: 14px;
    }
}
/* Mobile User Card Styling */
.user-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .user-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .user-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .user-card p {
    margin: 5px 0;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .dark-mode .user-card {
    background-color: #333;
    color: #fff;
  }
  
  .dark-mode .action-buttons button {
    color: #fff;
  }
  
.import-schedule-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

/* Dark mode styles */
.dark-mode .import-schedule-container {
  background-color: #292929;
}

.import-schedule-container h2 {
  text-align: center;
  color: #000000;
  font-size: 32px;
  margin-bottom: 20px;
}

/* Dark mode styles */
.dark-mode .import-schedule-container h2 {
  color: #ffffff;
}

.drop-zone {
  border: 2px dashed #007bff;
  border-radius: 8px;
  padding: 20% 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Dark mode styles */
.dark-mode .drop-zone {
  border-color: #66b2ff;
  background-color: #444444;
  color: #ffffff;
}

.drop-zone.dragging {
  background-color: #e0f7ff;
}

/* Dark mode dragging */
.dark-mode .drop-zone.dragging {
  background-color: #004080;
}

.file-input {
  display: none;
}

.button-row {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
@media screen and (max-width: 1168px) {
  .button-row {
    flex-direction: column-reverse;
  }
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 0 0 20%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.excel-icon {
  width: 30px;
  height: 30px;
}
.upload-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #218838;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Dark mode styles */
.dark-mode .download-button {
  background-color: #1c7c1c;
}

.dark-mode .download-button:hover {
  background-color: #145a14;
}


.upload-button:hover {
  background-color: #0056b3;
}

/* Dark mode hover */
.dark-mode .upload-button:hover {
  background-color: #003366;
}

.message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #e0ffe0;
  color: #007700;
}

/* Dark mode styles */
.dark-mode .message {
  background-color: #335533;
  color: #b2ffb2;
}

.error-list {
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffe0e0;
  color: #cc0000;
}

/* Dark mode styles */
.dark-mode .error-list {
  background-color: #662222;
  color: #ff9999;
}

.error-list h3 {
  margin-bottom: 10px;
}

/* Dark mode styles */
.dark-mode .error-list h3 {
  color: #ffcccc;
}

.error-list ul {
  padding-left: 20px;
}

/* Dark mode styles */
.dark-mode .error-list ul {
  color: #ffcccc;
}

.error-list li {
  margin-bottom: 5px;
}

/* Dark mode styles */
.dark-mode .error-list li {
  color: #ff9999;
}
.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.loading-icon .material-icons {
  font-size: 36px;
  animation: spin 1s linear infinite;
  color: #007bff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}